<template>
  <div class="container min-h-screen flex flex-col items-center justify-center">
    <div v-if="loadingPage" class="content">
      <p class="title title_loading">Aguarde, carregando...</p>
      <i class="fas fa-spinner fa-spin title_loading text-xl"></i>
    </div>
    <div v-else-if="valid" class="content">
      <div v-if="changedPassword" class="content">
        <p class="title title_success">Senha alterada!</p>
        <p class="subtitle">Redirecionando para a tela de login em breve...</p>
        <p class="text">Se não funcionar, <router-link class="link" :to="`/${req.replace('site/', '')}/login`">clique
            aqui</router-link></p>
      </div>
      <div v-else class="lg:w-1/2">
        <p class="title title_success">Cadastre sua senha de acesso ao seu Painel de Cliente</p>
        <v-input name="senha" type="password" v-model="form.senha" :error="errors.first('senha')" label="Senha"
          v-validate="'required|min:6'"></v-input>
        <v-input name="confirmacaoSenha" type="password" v-model="form.confirmacaoSenha"
          :error="errors.first('confirmacaoSenha')" label="Repetir senha"
          v-validate="{ required: true, min: 6, is: form.senha }"></v-input>
        <v-button class="flex justify-center" @click="submit" :loading="loading">Criar senha</v-button>
      </div>
    </div>
    <div v-else class="content">
      <p class="title title_error">Link inválido</p>
      <p class="subtitle">Redirecionando para a página principal em breve...</p>
      <p class="text">Se não funcionar, <router-link class="link" to="/">clique aqui</router-link></p>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import ForgotPassword from '@/services/Commons/ForgotPassword'
import { setTimeout } from 'timers'

export default {
  props: ['req'],
  components: { VButton, VInput },
  data() {
    return {
      valid: false,
      loadingPage: true,
      loading: false,
      changedPassword: false,
      error: null,

      email: null,
      userId: null,
      token: null,

      form: {
        senha: '',
        confirmacaoSenha: ''
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.error = null
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const response = await ForgotPassword.changePassword({ ...this.form, token: this.token, userId: this.userId }, this.req)
            this.changedPassword = true

            this.$store.dispatch('setLogout')
            this.$store.dispatch('setLogin', response.data.data)

            console.log('response alterar senha', response.data.data);

            if (response.data.data.roles.includes('tomador') || response.data.data.roles.includes('socio-tomador')) {
              setTimeout(() => this.$router.push(`/tomador/painel`), 100)
            } else if (response.data.data.roles.includes('investidor')) {
              setTimeout(() => this.$router.push(`/investidor/painel`), 100)
            } else if (response.data.data.roles.includes('parceiro_tomador')) {
              setTimeout(() => this.$router.push(`/parceiro/painel`), 100)
            }
          } catch (err) {
            this.loading = false
            this.valid = false
            setTimeout(() => this.$router.push('/'), 5000)
            console.error(err)
          }
        }
      }
    }
  },
  async mounted() {
    const { email, userId, token } = this.$route.query

    if (!(email && userId && token && this.req)) {
      this.valid = false
      this.loadingPage = false
      // setTimeout(() => this.$router.push('/'), 5000)
    } else {
      this.valid = true
      this.loadingPage = false
      this.email = email
      this.userId = userId
      this.token = token
    }
  }
}
</script>

<style lang="sass" scoped>

.content
  @apply w-full flex flex-col items-center justify-center border shadow-lg rounded-lg
  min-height: 10rem

.title
  @apply font-bold my-4 text-lg
  @screen lg
    @apply text-xl
  &_error
    @apply text-red-600
  &_loading, &_success
    @apply text-primary

.subtitle
  @apply opacity-75 text-center
  @screen lg
    @apply text-lg

.text
  @apply opacity-75 text-center text-sm
  @screen lg
    @apply text-base

.link
  @apply text-secondary
  &:hover, &:focus
    @apply font-bold cursor-pointer

</style>
